.App {
  text-align: center;
  justify-content: center;
}


.leaflet-container {
  width: 80vw; 
  height: 80vh; 
  text-align: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin: 50px;
}

